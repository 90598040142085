<template>
	<Spinner v-if="isLoading"></Spinner>
  	<router-view />
</template>

<!-- <script src="../src/assets/resource/js/common.js"></script> -->

<style>
	@import url('../src/assets/resource/css/reset.css');
	@import url('../src/assets/resource/css/common.css');
	/* @import url('../src/assets/resource/js/common.js'); */
	@import url('../src/assets/resource/css/font.css');
</style>

<script>
import Spinner from './components/common/Spinner.vue';
export default{
	components: {
		Spinner
	},
	data() {
		return {
			isLoading: false,
			urlList: [],
			pendingRequests: 0,
		}
	},
	watch: {
        $route(to, from) {
			if(to.path != from.path) {
				this.loginChk();
			}
			window.scrollTo(0, 0);
        },
    },
	methods: {
		setLoading(bool){
            this.isLoading = bool;
        },
		loginChk() {
			this.axios
				.get(this.api.member.info)
				.then((res) => {
					if(res.data){
						console.log("res.data: " + JSON.stringify(res.data));
						this.$store.commit('setStatus', true)
						this.$store.state.memberStore.memCi = res.data.memCi;
						this.$store.state.myInfoStore.memCi = res.data.memCi;
						this.$store.dispatch('setLoginStatus', true)
					}else{
						this.$store.commit('setStatus', false)
						this.$store.state.memberStore.memCi = '';
						this.$store.state.myInfoStore.memCi = '';
						this.$store.commit('myInfoInit')
					}
				})
				.catch((err) => {
					console.log(err);
				});
        },
	},
	created() {
		this.pendingRequests = 0
		this.urlList = []

		this.axios.interceptors.request.use(
            config => {
                if(config.url !== '/api/info.do'){
					this.pendingRequests++
                    this.setLoading(true);
                    this.urlList.push([config.url, config.params]);
                }
                return config;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        ),
        this.axios.interceptors.response.use(
            response => {
                if(response.config.url !== '/api/info.do'){
					this.pendingRequests--
					if(this.pendingRequests == 0) {
                        this.setLoading(false);
                    }
                    // if(JSON.stringify(this.urlList[this.urlList.length - 1]) == JSON.stringify([response.config.url, response.config.params])) {
                    //     this.setLoading(false);
                    // }
                }
                return response;
            },
            error => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        )
	},
	mounted() {
		this.loginChk();
	},
}
</script>